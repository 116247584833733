import { isThisISOWeek } from "date-fns";
import { defineStore } from "pinia";

import createSale from '../../valueObjects/sale'
import { useOnboardingStore } from "../app/onboarding";

export const useSaleFormStore = defineStore("saleFormStore", {

  state: () => ({

    http: useNuxtApp().$http,

    calc: useNuxtApp().$calc,

    analytics: useAnalyticsStore(),

    onboardingStore: useOnboardingStore(),

    productGridType: 'list',

    isBooted: false,

    sale: createSale({
      id: null,
      uuid: null,
      operation: 'sale',
      number: null,
      notes: null,
      customer: null,
      customer_id: null,
      salesman: null,
      sale_products: [],
      sale_payments: [],
      bill_receivables: [],
      general_discount: 0,
      status: 'draft'
    }),

    saleProductUpdateIndex: null,

    saleProductForm: {
      product: null,
      product_id: null,
      product_variant: null,
      product_variant_id: null,
      product_name: null,
      unit: null,
      quantity: 0,
      price: 0,
      product_discount: 0,
      shipping: 0,
      expenses: 0,
    }
  }),

  getters: {

    total() {
      return this.sale.sale_products.reduce((total, product) => {

        return total + Number(product.quantity) * Number(product.price);
      }, 0);
    },

    productTotal() {
      return this.sale.sale_products.reduce((total, saleProduct) => {

        return total + (Number(saleProduct.quantity) * Number(saleProduct.price));
      }, 0);
    },

    productSubTotal() {
      return this.sale.sale_products.reduce((total, saleProduct) => {

        return total + ((Number(saleProduct.quantity) * Number(saleProduct.price)) - Number(saleProduct.product_discount));

      }, 0);
    },

    generalDiscountPercent() {
      return this.total > 0 ? (this.sale.general_discount / this.productSubTotal) * 100 : 0;
    },

    netTotal() {
      return (this.productSubTotal - this.sale.general_discount) + this.sale.total_fees;
    },

    form() {
      return this.saleProductForm;
    },

    isInsert: ((state) => state.sale.id == null),

    productCount() {

      let products = this.sale.sale_products.map((saleProduct) => {
        let quantity = saleProduct.product?.allow_fractional_sales ? 1 : saleProduct.quantity;

        return { ...saleProduct, quantity: quantity };

      });

      return this.calc.sum(products, "quantity");
    }
  },

  actions: {

    removeEditingSaleProduct() {
      this.sale.sale_products.splice(this.saleProductUpdateIndex, 1);

      this.resetSaleProductForm();
    },

    removeSaleProduct(index) {
      this.sale.sale_products.splice(index, 1);
    },

    editSaleProduct(index) {
      this.resetSaleProductForm();

      this.saleProductUpdateIndex = index;

      this.saleProductForm = JSON.parse(JSON.stringify(this.sale.sale_products[index]));
    },

    storeSaleProduct() {
      if (this.saleProductUpdateIndex != null) {
        this.updateSaleProduct();
      } else {

        this.insertSaleProduct();
      }
    },

    insertSaleProduct() {
      this.sale.sale_products.push(this.saleProductForm);

      this.resetSaleProductForm();
    },

    updateSaleProduct() {
      this.sale.sale_products[this.saleProductUpdateIndex] = this.saleProductForm;

      this.resetSaleProductForm();
    },

    incrementSaleProductQuantity(product, productVariant, quantity = 1) {

      const index = this.sale.sale_products.findIndex((saleProduct) => {

        return saleProduct.product?.id === product.id && saleProduct.product_variant?.id === productVariant.id;
      });

      if (index === -1) {
        this.resetSaleProductForm();

        this.saleProductForm.product = product;
        this.saleProductForm.product_id = product.id;
        this.saleProductForm.product_variant = productVariant;
        this.saleProductForm.product_variant_id = productVariant.id;

        this.saleProductForm.product_name = product.name

        if (productVariant.name) {
          this.saleProductForm.product_name += ` - ${productVariant.name}`;
        }

        this.saleProductForm.quantity = quantity;

        this.saleProductForm.price = productVariant.price

        this.insertSaleProduct()

      } else {
        this.sale.sale_products[index].quantity += Number(quantity);
      }
    },

    resetSaleProductForm() {

      this.saleProductUpdateIndex = null;

      this.saleProductForm = {
        product: null,
        product_id: null,
        product_variant: null,
        product_variant_id: null,
        product_name: null,
        unit: null,
        quantity: 1,
        price: 0,
        product_discount: 0,
        shipping: 0,
        expenses: 0,
      };
    },

    async loadSale(id) {

      const data = await this.http.show('sale/sale', id);

      this.sale = createSale(data.sale);
    },

    async save(status) {

      this.sale.status = status;

      if (this.isInsert) {

        await this.store()

      } else {

        await this.update();
      }

      this.onboardingStore.completeStep('make_first_sale')
    },

    async store() {

      const data = await this.http.store("sale/sale", this.sale.getSale());

      this.sale.number = data.sale.number;
      this.sale.id = data.sale.id;
      this.sale.uuid = data.sale.uuid;
      this.sale.customer_id = data.sale.customer_id;
      this.sale.customer = data.sale.customer;

      this.analytics.track('Sale Created');
    },

    async update() {

      const data = await this.http.update("sale/sale", this.sale.id, this.sale.getSale());

      this.sale.number = data.sale.number;
      this.sale.id = data.sale.id;
      this.sale.uuid = data.sale.uuid;

      this.sale.customer_id = data.sale.customer_id;
      this.sale.customer = data.sale.customer;

      this.analytics.track('Sale Updated');
    },

    async setSale(sale) {
      this.sale = JSON.parse(JSON.stringify(sale));
    },

    async storeNfce() {

      let params = {
        sale_id: this.sale.id,
        modelo: '65',
      }

      return await this.http.store("sale/sale-nfe", params);
    }
  }
});